










































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { sendPagePreview } from '@/api/landingpage/pages'

@Component({
    name: 'PageEmailModal',
})
export default class extends Vue {
    private items = ['']
    private dialog = false
    private loading = false
    private pageId = ''
    resolve: any = null
    reject: any = null
    $refs: any

    public async save () {
        const valid = await this.$refs.emailForm.validate()
        if (!valid) return
        this.loading = true
        const toastOption = {
            heading: this.$t(`landingpages.pages.pageSentPreviewMessage`).toString(),
            timeout: 5000,
        }
        try {
            await sendPagePreview(this.items, this.pageId)
            this.$root.$vsToast(toastOption)
            this.close()
            this.loading = false
        } catch (e) {
            console.log(e)
            this.loading = false
            toastOption.heading = this.$t(`landingpages.pages.pageSentPreviewErrors.default`).toString()
            this.$root.$vsToast(toastOption)
        }
    }

    public open (pageId: string) {
        this.pageId = pageId
        this.items = ['']
        this.dialog = true
    }

    public close () {
        this.dialog = false
    }

    @Watch('items')
    limitItems () {
        if (this.items.length > 10) {
            this.$nextTick(() => this.items.pop())
        }
    }
}
