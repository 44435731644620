import JSZip from 'jszip'
import FileSaver from 'file-saver'

export const exportToZip = (htmlPage: string) => {
    const zip = new JSZip()
    const fn = 'lp_template_' + Date.now() + '.zip'
    zip.file('index.html', htmlPage + '')
    zip.generateAsync({ type: 'blob' })
        .then((content: any) => {
            FileSaver.saveAs(content, fn)
        })
}
